import logo from './logo.svg';
import './App.css';
import NavBar from "./components/Navbar/NavBar";
import Home from "./components/Home/Home";
import AboutUs from "./components/AboutUs/AboutUs";
import Unesco from "./components/Unesco/Unesco";
import Dolomites from "./components/Dolomites/Dolomites";
import Ladin from "./components/Ladin/Ladin";
import Partners from "./components/Partners/Partners";
import Products from "./components/Products/Products";
import Shop from "./components/Shop/Shop";
import MilomitesCura from "./components/MilomitesCura/MilomitesCura";
import Contact from "./components/Contact/Contact";
import GoogleMaps from "./components/GoogleMaps/GoogleMaps";
import {useEffect} from "react";
import favicon from "./logo.svg"

function App() {

    const productsAndPrices = [
        {
            weight: '120gr.',
            productName: 'FLORA ALPINA',
            price: 4,
        },
        {
            weight: '120gr.',
            productName: 'CIÖF DE MUNT',
            price: 4,
        },
        {
            weight: '250gr.',
            productName: 'ALPE PURA',
            price: 8,
        },
        {
            weight: '450gr.',
            productName: 'FLORA ALPINA',
            price: 10,
        },
        {
            weight: '450gr.',
            productName: 'CIÖF DE MUNT',
            price: 10,
        },
        {
            weight: '800gr.',
            productName: 'FLORA ALPINA',
            price: 16,
        },
        {
            weight: '800gr.',
            productName: 'CIÖF DE MUNT',
            price: 16,
        }
    ];

    let content = {
        Italian: {
            NavBar: {
                whoAreWe: 'Chi Siamo',
                dolomites: 'Dolomites',
                ourRoots: 'Le nostre radici',
                ourPartners: 'I nostri partner',
                milomitesHoney: 'Il miele Milomites',
                milomitesCura: 'MilomitesCura',
                contacts: 'Contatti',
            },
            Home: {
                milomites: 'milomites',
                honey: 'HONEY',
                mission: 'by bees for a sweet world',
                subtitle1: '… scegli il Miele Milomites\nper arricchire i tuoi momenti speciali',
                subtitle2: 'un tocco di salute e purezza delle Dolomiti\nin ogni dolce goccia.',
            },
            AboutUs: {
                WhoAreWe: {
                    textTop: 'Siamo più di apicoltori, siamo custodi appassionati di un’arte millenaria che abbraccia le vette delle montagne e celebra la ricchezza dell’ecosistema.',
                    textBottom: '… l’ammirazione per le api si intreccia con l’amore per la montagna e il rispetto per la biodiversità.'
                },
                Bees: {
                    title: 'Apis mellifera carnica',
                    textMiddle: ['Le api, cuori pulsanti della natura, intrecciano il filo della vita nel nostro mondo.',
                        'Sono i guardiani instancabili della biodiversità e architetti di ecosistemi vitali.',
                        'Ogni ape è un anello prezioso in questo equilibrio, la cui importanza risuona attraverso i campi e gli alveari.',
                        'Milomites accudisce alla ape carnica, ambisce a onorle, amarle e proteggerle, poiché nel farlo preserviamo il loro mondo e il nostro.',
                        'Le api sono i paladini della natura, e nel loro volo instancabile ci conducono verso un futuro in cui ogni angolo del nostro mondo continua a brulicare di vita.',],
                    textBottom: '… con le api per un mondo più armonioso e rassicurante oltre che dolce e gradevole.'
                },
                History: {
                    title: '… la nostra storia',
                    beginningHistoryText: 'La nostra missione ebbe inizio nel lontano 1953, quando il padre del oggi CEO Ploner Carlo, incominciò con la cura delle api. La sua giornata iniziava con il ronzio familiare delle api, compagni instancabili del suo piccolo regno. L’ape regina, con il suo seguito di operaie, creava un delicato equilibrio nella sua apicoltura.',
                    sloganPart1: 'La nostra passione brilla attraverso ogni goccia d’oro, e ogni sapore racconta la storia di una stagione, di un clima, di una montagna, del lavoro incessante di questo piccolo insetto, che da solo salva il mondo.',
                    passionText: 'Carlo aveva ereditato la sua passione per le api da sua madre, una donna anziana che gli aveva trasmesso il rispetto profondo per questi insetti laboriosi. La sua fattoria, incorniciata da campi di fiori selvatici, era un paradiso per le api. Ogni alveare era un microcosmo vibrante di attività, dove le api danzavano tra i fiori, raccogliendo il nettare dorato.',
                    recentHistoryText: ['Le sue mani, segnate dal lavoro e dalla passione, erano abili nel manipolare gli alveari con cura. Con occhi attenti, contava le api, valutava la salute degli alveari e raccoglieva il miele dorato. Ogni goccia di miele era un successo, un risultato dell\'impegno costante di Carlo e delle sue piccole compagne ali',
                        'Così nell’arco degli anni la sua apicolura crebbe fina a raggiungere più di 100 arni.', 'Nel 2016 con il sopravvento degli acciacchi dell’età, Carlo dovette ridurre il proprio lavoro per l’apicotura e così il figlio Felix con la moglie Antonella riprese la passione.', 'Oggi tutta la famiglia si prende cura della api e aiuta dove può.'],
                    sloganPart2: '… unisciti a noi in questo viaggio appassionato tra cielo e terra. Assapora il risultato di ore trascorse tra api, fiori alpini e la freschezza dell’aria di montagna.',
                    nowText: ['Oggi l’azienda agricola conta 85 arnie e produce con metodo tradizionale un miele di altissima qualità.',
                        'Milomites si trova nel Cuore delle Domomiti, nel Parco Naturale Fanes Senes Braies.', 'Milomites è membro di Agri.col.art'],
                }
            },
            Dolomites: {
                title: 'le nostre Dolomiti',
                subtitle: 'Le Dolomiti, riconosciute come Patrimonio dell’Umanità dell’UNESCO, rappresentano una delle aree montuose più straordinarie e significative del mondo.',
                slogan: '… le Dolomiti sono celebri per le loro cime imponenti, pareti verticali e paesaggi mozzafiato.',
                textFlora: 'Le Dolomiti ospitano una ricca diversità di flora e fauna, molte delle quali sono specie endemiche. È evidente la necessità di preservare e proteggere questo ecosistema unico.',
                textCulture: 'Le Dolomiti sono intrise di storia e cultura. Le comunità locali, le tradizioni, i villaggi e le testimonianze di un passato ricco si storia sono tutti parte integrante del paesaggio.',
            },
            Ladin: {
                title: 'La nostra cultura ladina',
                textTop: 'Nel cuore delle Dolomiti, tra vette maestose e valli incantevoli, fiorisce la cultura ladina come un delicato fiore alpino. Una melodia armoniosa di tradizioni e lingua, che trasporta chi ascolta in un mondo unico, ricco di storia e leggende.',
                slogan: '… la lingua ladina, con le sue sfumature poetiche e melodiche, è il tessuto che intreccia il passato e il presente della nostra comunità montana.',
                textBottom: ['In ogni borgo ladino, le pietre delle antiche case raccontano storie silenziose, mentre la lingua ladina è il filo conduttore che le connette. È un patrimonio prezioso, una ricchezza che va oltre le parole, spaziando tra le tradizioni culinarie, le danze folkloristiche e le feste popolari.', 'Nelle Dolomiti ladine, la cultura è un fluire continuo di festività e celebrazioni, una danza leggera tra generazioni che si tramandano segreti e tradizioni. È un patrimonio da preservare e valorizzare, un invito ad immergersi nelle profondità di una cultura che parla al cuore.', 'In un mondo che cambia rapidamente, la cultura ladina si erge come un faro di identità, una testimonianza del connubio tra passato e futuro. Attraverso la lingua, le tradizioni e l’arte, la cultura ladina continua a tessere una trama elegante e vibrante, rendendo ogni angolo delle Dolomiti una sinfonia di autenticità e bellezza senza tempo.'],
            },
            Partners: {
                title: '… i nostri Partner',
                agricolartDescription: '… un gruppo di amici, che seguono la tradizione ladina, appassionati di agricoltura sono riusciti ad organizzarsi in un consorzio agrario e a presentarsi al mercato dei contadini.',
                moreInfo: 'Per più informazioni:'
            },
            Products: {
                title: 'il nostro miele, un autentico tesoro',
                textTop: ['l Miele Milomites è una vera e propria meraviglia delle Dolomiti, un tesoro che incarna l’essenza di queste maestose montagne. Colto con passione e dedizione durante l’estate, offre un’esperienza gustativa senza pari.',
                    'La nostra promessa di purezza e freschezza è supportata da rigorosi controlli di qualità.'],
                slogan: '… non solo una delizia per il palato ma anche un autentico tesoro di benessere.',
                textBottom: ['Grazie alla presenza di piante come il rododendro, la campanula e il lampone, il nostro miele d’alta montagna diventa un nettare davvero esclusivo.',
                    'Oltre al piacere culinario che offre, il Miele Milomites è rinomato per i suoi benefici per la salute, grazie alle proprietà antiossidanti, antibatteriche e antinfiammatorie.'],
                FloraAlpina: {
                    productName: ['flora', 'alpina'],
                    productSlogan: '… dove la dolcezza incontra la freschezza selvaggia!',
                    descriptionTop: 'Immergiti in un’esplosione di sapore con il nostro Miele FLORA ALPINA, un’esperienza gustativa unica che unisce la dolcezza inconfondibile del miele alla freschezza selvaggia dei campi di tarassaco e dei prati dolomitici',
                    descriptionSlogan: 'Il nostro miele è come una festa per il palato, con una nota frizzante che ti farà ballare di gioia.',
                    descriptionBottom: ['Il Miele FLORA ALPINA deriva dai fiori dei prati primaverili con tanto tarassaco, morbido e cremoso, dal colore giallo vivo e dal profumo molto intenso e di sapore forte.',
                        'Fin dagli antichi romani è conosciuto come tonico e come regolatore intestinale.'],
                },
                CiofDeMunt: {
                    productName: ['ciöf', 'de munt'],
                    productSlogan: '… dove la montagna accarezza la tua anima!',
                    descriptionTop: 'Esplora i sentieri del gusto con CIÖF DE MUNT - un connubio perfetto tra la selvatichezza del bosco alpino e la dolcezza unica del nettare d’api.',
                    descriptionSlogan: 'ciöf de munt è una festa di sapori, con una nota vivace che ti regalerà un tripudio di esperienze gustative.',
                    descriptionBottom: ['Questo miele di alta montagna, dal colore scuro con richiamo del bosco alpino; profumo aromatico e dolce, E di sapore aromatico.',
                        'Già gli egiziani usavano il millefiori scuro come miele da tavola, come antisettico e per alleviare influenze e raffreddori.'],
                },
                AlpePura: {
                    productName: ['alpe', 'pura'],
                    productSlogan: '… l’eleganza della natura che si fonda in un nettare straordinario.',
                    descriptionTop: 'Esplora l’essenza raffinata delle Alpi nel nostro pregiato Miele ALPE PURA, un’inimitabile fusione tra i delicati fiori di rododendro delle malghe dolomitiche e le erbe medicinali alpine, un tributo alla maestosità naturale delle montagne.',
                    descriptionSlogan: 'un autentico gioiello culinario che celebra l’armonia elegante delle Alpi.',
                    descriptionBottom: ['Millefiori estivo, raro e pregiato, dal rododendro all’erica e lampone, dal colore ambrato con riflessi di rosso e arancione; il profumo richiama una miscela di caramello, camomilla, curcuma e zafferano.',
                        'Con caratteristiche antisettiche i montanari da sempre ne conoscono le proprietà depurative, ricostituenti, di calmante dei centri nervosi e utile contro l’artrite.'],
                }
            },
            Shop: {
                shop: 'Shop',
                sloganTop: '… ogni barattolo del nostro miele è un racconto scritto con amore, una dedica a ogni ape instancabile e a ogni momento trascorso in loro compagnia.',
                products: productsAndPrices,
                sloganBottom: '… acquistabile in sede o al mercato contadino',
                openingScheduleTitle: 'Orario di apertura:',
                openingDayRange: 'LU-VE',
                openingHourRange: '9.30 - 11.30 e 16.00 - 17.30',
                orAtAgricolart: 'o al mercato contadino'
            },
            MilomitesCura: {
                MainScreen: {
                    title: ['Milomites', 'Cura'],
                    textTop: ['Milomites trasforma l’apicoltura in un’esperienza avvincente con l’introduzione di MilomitesCura, un innovativo apiario olistico nel cuore del nostro paese.',
                        'L’apiario olistico è progettato con attenzione per offrire una connessione diretta con le api, garantendo al contempo il comfort e la sicurezza dei visitatori. Le arnie sono integrate nella struttura, permettendo l’accesso all’aria ma non agli insetti, creando un ambiente armonioso e profumato.'],
                    slogan: '… la nostra visione è creare un luogo di benessere, dove le persone possano immergersi nei benefici delle api e godere di momenti di relax immersi nella natura.',
                    textBottom: 'La struttura, costruita con materiali naturali e in sintonia con il clima locale, è un’oasi ecologica che ospita attività legate alla produzione di miele, propoli, polline e pappa reale. Solo in alcuni periodi dell’anno e fasce orarie predeterminate, gli ospiti potranno vivere questa esperienza unica.',
                },
                DescriptionScreen: {
                    textTop: 'MilomitesCura è più di un semplice apiario; è una struttura collaterale integrativa, un rifugio eco-sostenibile progettato da esperti. Qui, gli ospiti si sentiranno parte di uno sciame, avvolti dal richiamo e dal profumo delle api. Un luogo dove la natura offre pace, il legno trasmette calore e l’esperienza è autenticamente selvaggia. Tutto questo, naturalmente, in totale sicurezza.',
                    slogan: '… vieni a vivere l’emozione del contatto diretto con le api, immergendoti nel mondo di MilomitesCura, dove il benessere si fonde con la magia delle Dolomiti.',
                },
                ApiHumming: {
                    slogan: 'il benessere della persona',
                    textApiHumming: ['- ascoltare(ApiHumming): A gruppi di 4 staranno per 15 minuti all’interno dell’apiario per ascoltare e respirare e quindi godere dei benefici fisici e psichici. Il beehumming è l\'attività di ascolto del suono dato dalle api che si trovano sul favo. È diverso dal ronzio delle api in volo.',
                    'È un suono continuo e più tenue. Coinvolgente. Arriva a toccare corde profonde della nostra anima. Rientra tra quei suoni naturali riconosciuti per la loro particolare frequenza, in grado di provocare un effetto di rilassamento sul cervello umano.'],
                },
                ApiAir: {
                    sloganTop: 'il benessere della persona',
                    textApiAir: ['- respirare(ApiAir): L\'aria delle api è invece l\'insieme delle molecole volatili prodotte dentro un alveare. Si tratta di centinaia di molecole differenti come un concentrato di odori di propoli, miele, polline, pappa reale, cera e del corpo delle api stesse.', 'Nella pratica dell\'aromaterapia esiste una differenziazione tra le cosiddette note di "testa", note di "mezzo" e note di "base". Queste stimolano rispettivamente il cervello, la parte centrale del corpo e gli organi genitali.'],
                    sloganBottom: ['- comprendere (Apimeditazione)', '- riposare o rilassarsi (area benessere)'],
                }
            },
            End: {
                producerLabel: 'Prodotto e confezionato in Italia da:',
                producerInfo: ['Fam. Ploner, dott. Felix', 'La Pli 7', '39030 Marebbe (BZ)', 'Val Badia', 'Italia'],
                producerTel: 'tel. +39 3336533281',
            }
        },
        German: {},
        English: {}
    }

    // TODO: select language automatically

    content = content.Italian;

    useEffect(() => {
        document.title = 'Milomites'; // Change the title
        setFavicon(); // Change the favicon
    }, []);

    const setFavicon = () => {
        const link = document.querySelector("link[rel*='icon']") || document.createElement('link');
        link.type = 'image/x-icon';
        link.rel = 'shortcut icon';
        link.href = favicon;
        document.getElementsByTagName('head')[0].appendChild(link);
    };


    return (
        <div className="App">
            <NavBar content={content.NavBar}/>
            <Home content={content.Home}/>
            <Products content={content.Products}/>
            <Shop content={content.Shop}/>
            <AboutUs content={content.AboutUs}/>
            <Unesco/>
            <Dolomites content={content.Dolomites}/>
            <Ladin content={content.Ladin}/>
            <Partners content={content.Partners}/>
            <MilomitesCura content={content.MilomitesCura}/>
            <Contact content={content.End}/>
            <GoogleMaps />
        </div>
    );
}

export default App;
