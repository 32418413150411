import React from "react";
import "./Ladin.css";
import {convertListToParagraphs} from "../utils/utils";

const Ladin = ({content}) => {

    const textBottom = convertListToParagraphs(content.textBottom);

    return(
        <section className={"ladin-section"}>
            <h1 className={"ladin-title"}>{content.title}</h1>
            <p className={"ladin-text-top"}>{content.textTop}</p>
            <p className={"ladin-slogan"}>{content.slogan}</p>
            <div className={"ladin-text-bottom-div"}>
                {textBottom}
            </div>
        </section>
    )
}

export default Ladin;