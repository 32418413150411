export function convertListToParagraphs(arr) {
    let paragraphs = [];
    for (const i in arr) {
        paragraphs.push(<p>{arr[i]}</p>);
    }
    return paragraphs;
}

export function convertListToH1(arr) {
    let paragraphs = [];
    for (const i in arr) {
        paragraphs.push(<h1>{arr[i]}</h1>);
    }
    return paragraphs;
}

export const replaceWordsWithColoredSpans = (text, wordsToColor) => {
    if (text.constructor !== Array) {
        text = [text];
    }
    return text.map((w) => {
        return w.split(' ').map((word, index) => {
            const isColored = wordsToColor.includes(word.replace(/[.,]/g, '')); // Check if word is in the wordsToColor array
            return isColored ? <span key={index}>{word}&nbsp;</span> : word + ' ';
        })
    });
};