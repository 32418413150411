import React from "react";
import "./Unesco.css";
import unesco from "../../assets/images/unesco.jpg"

const Unesco = () => {
    return (
        <section className={"unesco-section"} style={{backgroundImage: "url(" + unesco + ")"}}>
            <div className={"unesco-primary-title"}>
                <h1 id={"unesco-honey"}>the honey</h1>
                <h1>from the</h1>
                <h1>DOLOMITES</h1>
            </div>
            <div className={"unesco-secondary-title"}>
                <h1>UNESCO</h1>
                <h1>WORLD</h1>
                <h1>HERITAGE</h1>
            </div>
        </section>
    )
}

export default Unesco;