import './GoogleMaps.css';

const GoogleMaps = ({content}) => {
  return(
      <section className={"google-maps-section"}>
          <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2735.2874831448758!2d11.918402476469664!3d46.71979097112234!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x477822436b7ea3cd%3A0xc1e7511092bb270!2sMilomites%20Honey!5e0!3m2!1sde!2sit!4v1702407103359!5m2!1sde!2sit" allowFullScreen="" loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe>
      </section>
  )
}

export default GoogleMaps;