import WhoAreWe from "./WhoAreWe";
import Video from "./Video";
import Bees from "./Bees";
import History from "./History";

const AboutUs = ({content}) => {
    return (
        <section className={"about-us-section"}>
            <WhoAreWe content={content.WhoAreWe}/>
            <Video/>
            <Bees content={content.Bees}/>
            <History content={content.History}/>
        </section>
    );
}

export default AboutUs;