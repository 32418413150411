import React from "react";
import { useState } from "react";
import "./NavBar.css";
import { Link } from "react-scroll";
import menu from "../../assets/icons/menu.svg";
import close from "../../assets/icons/close.svg";
import logo from "../../assets/icons/logo.png";

const NavBar = ({content}) => {

    const [showNavbar, setShowNavbar] = useState(false)
    const handleShowNavbar = () => {
        if (window.innerWidth <= 620)
            setShowNavbar(!showNavbar)
    }
    return (
        <nav className="navbar">
            <div className="container">
                <div>
                    <img className="logo" src={logo} />
                </div>
                <div onClick={handleShowNavbar}>
                    <img src={showNavbar ? close : menu} id="menu-icon"/>
                </div>
                <div className={`nav-elements  ${showNavbar && 'active'}`}>
                    <hr style={{
                        margin: 0,
                        visibility: showNavbar ? "visible" : "hidden",
                    }}/>
                    <ul>
                        <li>
                            <Link to="products-section" spy={true} smooth={true} onClick={handleShowNavbar} offset={-64}>
                                {content.milomitesHoney}
                            </Link>
                        </li>
                        <li>
                            <Link to="about-us-section" spy={true} smooth={true} onClick={handleShowNavbar} offset={-64}>
                                {content.whoAreWe}
                            </Link>
                        </li>
                        <li>
                            <Link to="unesco-section" spy={true} smooth={true} onClick={handleShowNavbar} offset={-64}>
                                {content.dolomites}
                            </Link>
                        </li>
                        <li>
                            <Link to="ladin-section" spy={true} smooth={true} onClick={handleShowNavbar} offset={-64}>
                                {content.ourRoots}
                            </Link>
                        </li>
                        <li>
                            <Link to="partners-section" spy={true} smooth={true} onClick={handleShowNavbar} offset={-64}>
                                {content.ourPartners}
                            </Link>
                        </li>
                        <li>
                            <Link to="milomites-cura-section" spy={true} smooth={true} onClick={handleShowNavbar} offset={-64}>
                                {content.milomitesCura}
                            </Link>
                        </li>
                        <li>
                            <Link to="contact-section" spy={true} smooth={true} onClick={handleShowNavbar} offset={-64}>
                                {content.contacts}
                            </Link>
                        </li>
                    </ul>
                </div>
            </div>
        </nav>
    )
};

export default NavBar;
