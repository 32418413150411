import React from "react";
import "./Home.css"
import "../../fonts/caviar_dreams/CaviarDreams_Bold.ttf"

const Home = ({content}) => {

    return (
        <section className={"home-section"}>
            <div className={"home-title"}>
                <h1>{content.milomites}</h1>
                <h2>{content.honey}</h2>
            </div>
            <div className={"home-subtitles"}>
                <p>{content.subtitle1}</p>
                <p>{content.subtitle2}</p>
            </div>
            <div className={"home-mission"}>
                <h1>{content.mission.toUpperCase()}</h1>
            </div>

        </section>
    )
}

export default Home;