import './AlpePura.css'
import {convertListToH1, convertListToParagraphs} from "../utils/utils";

const AlpePura = ({content}) => {
    const productName = convertListToH1(content.productName)
    const descriptionBottom = convertListToParagraphs(content.descriptionBottom)

    return (
        <section className={"alpe-pura-section"}>
            <section className={"alpe-pura-product-section"}>
                <div className={"alpe-pura-product-title"}>
                    {productName}
                </div>
                <p className={"alpe-pura-product-slogan"}>{content.productSlogan}</p>
            </section>
            <section className={"alpe-pura-information-section"}>
                <div className={"alpe-pura-product-title"}>
                    {productName}
                </div>
                <p className={"alpe-pura-information-slogan"}>{content.descriptionSlogan}</p>
                <p className={"alpe-pura-text-top"}>{content.descriptionTop}</p>
                <div className={"alpe-pura-text-bottom"}>
                    {descriptionBottom}
                </div>
            </section>
        </section>
    );
}

export default AlpePura;