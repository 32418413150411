import React from "react";
import "./Dolomites.css";

const Dolomites = ({content}) => {
    return(
        <section className={"dolomites-section"}>
            <h1 className={"dolomites-title"}>{content.title}</h1>
            <h1 className={"dolomites-subtitle"}>{content.subtitle}</h1>
            <p className={"dolomites-slogan"}>{content.slogan}</p>
            <div className={"dolomites-text-div"}>
                <p className={"dolomites-flora"}>{content.textFlora}</p>
                <p className={"dolomites-culture"}>{content.textCulture}</p>
            </div>
        </section>
    )
}

export default Dolomites;