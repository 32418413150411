import './CiofDeMunt.css';
import {convertListToH1, convertListToParagraphs} from "../utils/utils";

const CiofDeMunt = ({content}) => {
    const productName = convertListToH1(content.productName)
    const descriptionBottom = convertListToParagraphs(content.descriptionBottom)

    return (
        <section className={"ciof-de-munt-section"}>
            <section className={"ciof-de-munt-product-section"}>
                <div className={"ciof-de-munt-product-title"}>
                    {productName}
                </div>
                <p className={"ciof-de-munt-product-slogan"}>{content.productSlogan}</p>
            </section>
            <section className={"ciof-de-munt-information-section"}>
                <div className={"ciof-de-munt-product-title"}>
                    {productName}
                </div>
                <p className={"ciof-de-munt-text-top"}>{content.descriptionTop}</p>
                <p className={"ciof-de-munt-information-slogan"}>{content.descriptionSlogan}</p>
                <div className={"ciof-de-munt-text-bottom"}>
                    {descriptionBottom}
                </div>
            </section>
        </section>
    );
}

export default CiofDeMunt;