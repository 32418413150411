import './Contact.css';
import logo from '../../assets/icons/logo.svg'
import {convertListToParagraphs} from "../utils/utils";

const Contact = ({content}) => {
    const producerInfo = convertListToParagraphs(content.producerInfo);

    return (
        <section className={"contact-section"}>
            <img className={"end-logo"} src={logo}/>
            <p>{content.producerLabel}</p>
            <div className={"contact-producer-info"}>
                {producerInfo}
            </div>
            <p>{content.producerTel}</p>
        </section>
    );
}

export default Contact;