import './Bees.css';
import {convertListToParagraphs} from "../utils/utils";

const Bees = ({content}) => {
    const textMiddle = convertListToParagraphs(content.textMiddle);

    return (
        <section className={'bees-section'}>
            <div>
                <h1>{content.title}</h1>
                <div className={'text-middle'}>
                    {textMiddle}
                </div>
                <p className={'text-bottom'}>{content.textBottom}</p>
            </div>
        </section>
    )
}

export default Bees;