import './MilomitesCura.css';
import MilomitesCuraMainScreen from "./MilomitesCuraMainScreen";
import ApiHumming from "./ApiHumming";
import ApiAir from "./ApiAir";
import MilomitesCuraDescriptionScreen from "./MilomitesCuraDescriptionScreen";

const MilomitesCura = ({content}) => {
    return (
        <section className={"milomites-cura-section"}>
            <MilomitesCuraMainScreen content={content.MainScreen}/>
            <MilomitesCuraDescriptionScreen content={content.DescriptionScreen}/>
            <ApiHumming content={content.ApiHumming}/>
            <ApiAir content={content.ApiAir}/>
        </section>
    )
}

export default MilomitesCura;