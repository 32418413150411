import './ApiHumming.css';
import {convertListToParagraphs, replaceWordsWithColoredSpans} from "../utils/utils";

const ApiHumming = ({content}) => {
    const wordsToColor = ['ascoltare(ApiHumming):'];
    const apiHummingText = convertListToParagraphs(replaceWordsWithColoredSpans(content.textApiHumming, wordsToColor));

    return (
        <section className={"api-humming-section"}>
            <p className={"api-humming-slogan"}>{content.slogan}</p>
            <div className={"api-humming-text"}>
                {apiHummingText}
            </div>
        </section>
    )
}

export default ApiHumming;