import React from "react";
import "./Products.css";
import FloraAlpina from "./FloraAlpina";
import spoons from '../../assets/images/spoons.jpg';
import {convertListToParagraphs} from "../utils/utils";
import CiofDeMunt from "./CiofDeMunt";
import AlpePura from "./AlpePura";

const Products = ({content}) => {
    const textTop = convertListToParagraphs(content.textTop);
    const textBottom = convertListToParagraphs(content.textBottom);

    return(
        <section className={"products-section"}>
            <section className={"products-introduction"}>
                <h1>{content.title}</h1>
                <img src={spoons}/>
                <div>
                    {textTop}
                    <p className={"products-slogan"}>{content.slogan}</p>
                    {textBottom}
                </div>
            </section>
            <FloraAlpina content={content.FloraAlpina}/>
            <CiofDeMunt content={content.CiofDeMunt}/>
            <AlpePura content={content.AlpePura}/>
        </section>
    )
}

export default Products;