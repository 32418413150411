import './MilomitesCuraDescriptionScreen.css';
import {replaceWordsWithColoredSpans} from "../utils/utils";

const MilomitesCuraDescriptionScreen = ({content}) => {

    const wordsToColor = ['MilomitesCura'];

    return (
        <section className={"milomites-cura-description-section"}>
            <p className={"milomites-cura-description-text-top"}>{replaceWordsWithColoredSpans(content.textTop, wordsToColor)}</p>
            <p className={"milomites-cura-description-slogan"}>{replaceWordsWithColoredSpans(content.slogan, wordsToColor)}</p>
        </section>
    )
}

export default MilomitesCuraDescriptionScreen;