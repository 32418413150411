import './MilomitesCuraMainScreen.css';
import {convertListToH1, convertListToParagraphs} from "../utils/utils";

const MilomitesCuraMainScreen = ({content}) => {
    let title = convertListToH1(content.title);
    let textTop = convertListToParagraphs(content.textTop);

    return (
        <section className={"milomites-cura-main-section"}>
            <div className={"milomites-cura-main-section-title"}>
                {title}
            </div>
            <div className={"milomites-cura-main-section-text-top"}>
                {textTop}
            </div>
            <p id={"milomites-cura-main-section-slogan"}>{content.slogan}</p>
            <p id={"milomites-cura-main-section-text-bottom"}>{content.textBottom}</p>
        </section>
    )
}

export default MilomitesCuraMainScreen;