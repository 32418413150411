import './WhoAreWe.css';

const WhoAreWe = ({content}) => {
    return(
        <section className={'who-are-we-section'}>
            <p className={'text-top'}>{content.textTop}</p>
            <p className={'text-bottom'}>{content.textBottom}</p>
        </section>
    );
}

export default WhoAreWe;