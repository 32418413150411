import './ApiAir.css';
import {convertListToParagraphs, replaceWordsWithColoredSpans} from "../utils/utils";

const ApiAir = ({content}) => {
    const wordsToColor = ['respirare(ApiAir):'];
    const apiAirText = convertListToParagraphs(replaceWordsWithColoredSpans(content.textApiAir, wordsToColor));
    const sloganBottom = convertListToParagraphs(content.sloganBottom);

    return (
        <section className={"api-air-section"}>
            <p className={"api-air-slogan-top"}>{content.sloganTop}</p>
            <div className={"api-air-text"}>
                {apiAirText}
            </div>
            <div className={"api-air-slogan-bottom"}>
                {sloganBottom}
            </div>
        </section>
    )
}

export default ApiAir;