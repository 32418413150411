import React from "react";
import "./Partners.css";
import video from "../../assets/videos/Agricolart2020.m4v";
import poster from "../../assets/images/agricolartPoster.png";
import agricolart_logo from "../../assets/images/agricolart_logo_gray.png"

const Partners = ({content}) => {
    return(
        <section className={"partners-section"}>
            <div className={"partners-image-div"}>
                <img src={agricolart_logo}/>
            </div>
            <p className={"partners-description"}>{content.agricolartDescription}</p>
            <p className={"partners-more-info"}>{content.moreInfo}
                <br/>
                <a href={"https://agricolart.it"}>agricolart.it</a>
            </p>
            <h1 className={"partners-title"}>{content.title}</h1>
            <video id={'agricolart-video'} poster={poster} controls>
                <source src={video} type="video/mp4"/>
                <p>Your browser does not support the video tag.</p>
            </video>
        </section>
    )
}

export default Partners;