import './Shop.css';

const Shop = ({content}) => {
    function createProductsTableRows(products) {
        let rows = [];
        let previousWeight = '-1';

        for (let i in products) {
            if (products[i].weight !== previousWeight) {
                rows.push(<tr className={"shop-products-table-row-gap"}></tr>);
            }
            rows.push(
                <tr>
                    <td className={"shop-products-table-cell-gap"}>{products[i].weight}</td>
                    <td className={"shop-products-table-cell-gap"}>{products[i].productName}</td>
                    <td>{products[i].price.toFixed(2).toLocaleString()} EURO</td>
                </tr>
            )
            previousWeight = products[i].weight;
        }

        return rows
    }

    const productsTableRows = createProductsTableRows(content.products);

    return(
        <section className={"shop-section"}>
            <h1 className={"shop-title"}>{content.shop}</h1>
            <p className={"shop-slogan-top"}>{content.sloganTop}</p>
            <table className={"shop-products-table"}>
                <tbody>
                    {productsTableRows}
                </tbody>
            </table>
            <div className={"shop-slogan-bottom-div"}>
                <p className={"shop-slogan-bottom"}>{content.sloganBottom}</p>
                <p className={"shop-slogan-bottom"}>{content.openingScheduleTitle}</p>
                <p className={"shop-slogan-bottom"}>{content.openingDayRange}</p>
                <p className={"shop-slogan-bottom shop-opening-hour-range"}>{content.openingHourRange}</p>
                <p className={"shop-slogan-bottom"}>{content.orAtAgricolart}</p>
            </div>
        </section>
    );
}


export default Shop;