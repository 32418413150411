import fullVideo from '../../assets/videos/milomites.mp4';
import poster from '../../assets/images/milomitesPoster.png';
import './Video.css';

const Video = () => {
    return(
        <section className={'about-us-video-section'}>
            <video id={'milomites-video'} poster={poster} controls>
                <source src={fullVideo} type="video/mp4"/>
                <p>Your browser does not support the video tag.</p>
            </video>
        </section>
    );
}

export default Video;