import './History.css';
import {convertListToParagraphs} from "../utils/utils";


const History = ({content}) => {
    const recentHistoryText = convertListToParagraphs(content.recentHistoryText);
    const nowText = convertListToParagraphs(content.nowText);

    return (
        <section className={'history-section'}>
            <section className={'first-part'}>
                <h1>{content.title}</h1>
                <p className={'text'}>{content.beginningHistoryText}</p>
                <p className={'slogan'}>{content.sloganPart1}</p>
                <p className={'text'}>{content.passionText}</p>
            </section>
            <section className={'second-part'}>
                <div>
                    {recentHistoryText}
                </div>
                <p>{content.sloganPart2}</p>
                <div>
                    {nowText}
                </div>
            </section>
        </section>
    );
}

export default History;